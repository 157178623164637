<div class="modal-main">
  <div class="close-modal" style="display: flex; height: 40px">
    <div style="flex: 0 0 50%; display: flex; align-items: center">
      <button class="round-icon-button" mat-icon-button (click)="onBack()">
        <mat-icon aria-label="Back" class="round-icon-pull-right"
          >arrow_back_ios</mat-icon
        >
      </button>
      <p style="flex: 0 0 90px; margin-left: -5px; font-size: 14px">
        <a style="color: white !important">Prev</a>
      </p>
    </div>
    <div
      style="
        display: flex;
        flex: 0 0 50%;
        align-items: center;
        justify-content: right;
      "
    >
      <button mat-icon-button mat-dialog-close class="round-icon-button">
        <mat-icon class="round-icon-center">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="modal-header-container">
    <h3>New Event</h3>
  </div>

  <div style="min-height: 100%">
    <div *ngIf="creatingEvent()" class="creating-event">
      <h3>Creating Event...</h3>
      <mat-progress-spinner
        value="100"
        mode="indeterminate"
        color="primary"
        diameter="75"
        strokeWidth="7"
      ></mat-progress-spinner>
    </div>

    <div *ngIf="!creatingEvent()" class="main-container">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-dialog-content
          style="
            padding-top: 10px;
            padding-bottom: 0px;
            color: black;
            text-align: center;
            width: 340px;
          "
        >
          <!-- <p><strong>Name Your Look</strong></p>

          <mat-form-field appearance="outline" hideRequiredMarker>
            <mat-label>Look Name</mat-label>
            <input matInput formControlName="lookName" />
          </mat-form-field> -->

          <p style="margin-top: 0px">
            <strong>Tell Us About Your Event</strong>
          </p>

          <div style="
            text-align: left;
            width: 100%;
            margin-bottom: 10px;
            font-size: 14px;
          "
          >
          * Required
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Event Name</mat-label>
            <input matInput formControlName="eventName" required />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Event Type</mat-label>
            <mat-select formControlName="eventType" required>
              <mat-option *ngFor="let eventType of EventTypeOptions" [value]="eventType">{{eventType}}</mat-option>
              <!-- <mat-option value="Prom">Prom</mat-option>
              <mat-option value="Quinceañera">Quinceañera</mat-option>
              <mat-option value="Special Event">Special Event</mat-option> -->
            </mat-select>
          </mat-form-field>

          <app-date-form
            label="Event Date"
            appearance="outline"
            [hideHint]="true"
            [displayError]="false"
            (valueChange)="setDate($event)"
            [required]="true"
            [minDate]="minDate()"
            [maxDate]="maxDate"
            [ngClass]="{'error-padding': eventDateInvalid()}"
          >
          </app-date-form>

          <mat-form-field appearance="outline">
            <mat-label>Individual or Group Order?</mat-label>
            <mat-select formControlName="individualOrGroup" required>
              <mat-option [value]="IndividualOrGroup.individual">Individual Order</mat-option>
              <mat-option [value]="IndividualOrGroup.group">Group Order</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>How do you want to order?</mat-label>
            <mat-select
              formControlName="onlineOrInStore"
              required
            >
              <mat-option [value]="OnlineOrInStore.inStore">Order In-Store</mat-option>
              <mat-option [value]="OnlineOrInStore.online">Order Online</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-error *ngIf="form.hasError('dateIsTooSoon')" style="font-size: small;">
            event date too soon for online ordering
            <!-- *Your event date is too soon. <br>Online ordering is unavailable
            for events less than three weeks <br>from time of ordering. -->
          </mat-error>
        </mat-dialog-content>

        <mat-dialog-actions style="padding-top: 0px; margin-bottom: 10px">
          <button mat-flat-button color="primary" [disabled]="form.invalid">SAVE EVENT</button>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</div>

<!-- <div *ngIf="creatingEvent" class="creating-event">
  <h3>Creating Event...</h3>
  <mat-progress-spinner
    value="100"
    mode="indeterminate"
    color="primary"
    diameter="75"
    strokeWidth="7"
  ></mat-progress-spinner>
</div>

<div *ngIf="!creatingEvent" class="main-container">
  <div class="header">
    <button mat-button (click)="onBack()">
      <mat-icon>arrow_back</mat-icon> Back
    </button>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h2 mat-dialog-title>Tell us about your event!!!!</h2>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Look Name</mat-label>
        <input matInput formControlName="lookName" />
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Event Name</mat-label>
        <input matInput formControlName="eventName" />
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Event Type</mat-label>
        <mat-select formControlName="eventType">
          <mat-option value="Wedding">Wedding</mat-option>
          <mat-option value="Prom">Prom</mat-option>
          <mat-option value="Quinceañera">Quinceañera</mat-option>
          <mat-option value="Special Event">Special Event</mat-option>
        </mat-select>
      </mat-form-field>

      <app-date-form
        (formReady)="addChildForm('eventDate', $event)"
        label="Event Date"
        appearance="outline"
        (valueChange)="setDate($event)"
        [hideRequiredMarker]="true"
      >
      </app-date-form>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Individual or Group Order?</mat-label>
        <mat-select formControlName="individualOrGroup">
          <mat-option value="individual">Individual Order</mat-option>
          <mat-option value="group">Group Order</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>How do you want to order?</mat-label>
        <mat-select
          (selectionChange)="inStoreOnlineChange()"
          formControlName="onlineOrInStore"
        >
          <mat-option value="inStore">Order In-Store</mat-option>
          <mat-option value="online">Order Online</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-error *ngIf="dateWarning">
        *Your event date is too soon. Online ordering<br />is unavailable for
        events less than two weeks<br />from time of ordering.
      </mat-error>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-flat-button color="primary">SAVE EVENT</button>
    </mat-dialog-actions>
  </form>
</div> -->
