import { Injectable, inject } from '@angular/core';
import { ECOM_EVENT_API_SERVICES } from '@jfw-library/ecommerce/api-services';
import { EventService } from '@jfw-library/ecommerce/core';
import {
  EventMember,
  Measurements,
  EcomEventV5Types as types,
} from 'common-types';
import { Observable, firstValueFrom, map, tap } from 'rxjs';
import { MeasurementApiService } from './measurement-api.service';
@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  private ecomUpdateEventApiService = inject(ECOM_EVENT_API_SERVICES.v7);

  constructor(private measurementApiService: MeasurementApiService, private eventService: EventService,) { }

  public updateMemberMeasurements(eventId: string, members: EventMember[]) {
    const measurementsMap = this.generateMeasurementsMap(members);
    const lastUpdateId = this.eventService.setLastUpdateId('updateMemberMeasurements in MeasurementService');
    const data = {
      ...measurementsMap,
      lastUpdateId,
    };

    return firstValueFrom(
      this.ecomUpdateEventApiService.updateMeasurements(
        eventId,
        data
      )
    );
  }

  generateMeasurementsMap(
    members: EventMember[]
  ): types.UpdateMeasurementsData {
    let map: types.UpdateMeasurementsData = { measurementsMap: {} };
    members.forEach((member) => {
      if (member.id && member.measurements) {
        map.measurementsMap[member.id] = member.measurements;
      }
    });
    return map;
  }

  public getMeasurementReference(
    memberId: string,
    email: string,
    shoeSize: string
  ): Observable<any> {
    return this.measurementApiService.apiGetMeasurementReference(
      memberId,
      email,
      shoeSize
    );
  }

  public getMeasurements(memberId: string): Observable<Measurements> {
    return this.measurementApiService.apiGetMeasurements(memberId).pipe(
      map((m) => this.convert(m)),
      tap((x: any) =>
        console.log(`processed measurements response: ${JSON.stringify(x)}`)
      )
    );
  }

  convert(m: any): Measurements {
    var mapped: Measurements = {
      derivedFrom: 'tool',
      chest: this.cmToInches(m.UpdatePerson.volume_params.chest),
      overarm: this.cmToInches(m.UpdatePerson.volume_params.overarm_girth),
      // Waist = hip - 6inches
      waist: this.cmToInches(m.UpdatePerson.volume_params.low_hips)! - 6,
      hip: this.cmToInches(m.UpdatePerson.volume_params.low_hips),
      stomach: this.cmToInches(m.UpdatePerson.volume_params.abdomen),
      neck: this.cmToInches(m.UpdatePerson.volume_params.neck),
      sleeve: this.cmToInches(
        m.UpdatePerson.front_params.back_neck_point_to_wrist_length_1_5_inch
      ),
      // insleeve: 1,
      outseam:
        this.cmToInches(
          m.UpdatePerson.front_params.outseam_from_upper_hip_level
        )! + 1.5,
      // inseam: 1,
      height: this.cmToFeetAndInchesString(m.UpdatePerson.height),
      weight: this.kilogramsToPounds(m.UpdatePerson.weight),
      // coatSize: "",
      // vestSize: "",
      // shoeSize: ""
    };

    return mapped;
  }

  cmToInches(cm: any): number | undefined {
    return Number((cm / 2.54).toFixed(2));
  }

  cmToFeetAndInchesString(cm: number): string {
    const inches = cm / 2.54;
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    return `${feet}'${remainingInches}"`;
  }

  kilogramsToPounds(kilograms: number): number {
    const pounds = kilograms * 2.20462;
    return pounds;
  }
}
